import React from "react"

import Layout from "../components/layout"

import { graphql } from "gatsby"
import Image from "gatsby-image"
import About from "../components/about"
import Button from "../styles/Button"
import Services from "../components/services"
import Accordion from "../styles/Accordion"
// import Credentials from "../components/credentials"
// import SlickSlider from "../styles/SlickSlider"
import Footer from "../components/footer"
import Jobs from "../components/jobs"
import Hero from "../components/hero"
import SEO from "../components/seo"

export default function IndexPage({ data }) {
  return (
    <Layout>
      <SEO title="Startseite" />
      <Hero>
        <Image
          fluid={
            data.ueber.edges[0].node.frontmatter.s_bild.childImageSharp
            .fluid
          }
          imgStyle={{
            objectFit: "cover",
            objectPosition: "50% 50%",
          }}
        />
      </Hero>
      <About>
        <h4>{ data.ueber.edges[0].node.frontmatter.marker }</h4>
        <h2
          dangerouslySetInnerHTML={{ __html: data.ueber.edges[0].node.frontmatter.einstieg }}
        />
        <p>{ data.ueber.edges[0].node.frontmatter.beschreibung }</p>
        <Button internalLink="/ueber-uns/" primary="true">Mehr über uns</Button>
        <div className="has-flex">
          <div className="w-half">
            <Image
              css={`
                margin-top: -${props => props.theme.spacingBase};

                @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
                  margin-top: 0rem;
                }
              `}
              fluid={
                data.ueber.edges[0].node.frontmatter.bild.childImageSharp
                  .fluid
              }
            />
          </div>
        </div>
      </About>
      <Services>
        <h4>{ data.leistungen.edges[0].node.frontmatter.marker }</h4>
        <h2
          dangerouslySetInnerHTML={{ __html: data.leistungen.edges[0].node.frontmatter.einstieg }}
        />
        {data.leistungen.edges[0].node.frontmatter.leistung.map((el, idx) => (
          <Accordion key={idx} title={el.titel}>
            <p>{ el.beschreibung }</p>
            <Button externalLink={'mailto:office@schiefermayr.energy'} primary>Jetzt Service anfragen</Button>
          </Accordion>
        ))}
      </Services>
      {/* <Credentials>
        <h4>{ data.referenzen.edges[0].node.frontmatter.marker }</h4>
        <h2
          key={`h2`}
          id="___gatsby"
          dangerouslySetInnerHTML={{ __html: data.referenzen.edges[0].node.frontmatter.einstieg }}
        />
        {data.referenzen.edges[0].node.frontmatter.referenz.map((el, idx) => (
          <Accordion title={el.titel} key={idx} i={el}>
            <SlickSlider>
              {
                el.galerie.map((element, index) => {
                  return <div key={idx + '-' + index}>
                      <Image
                        fluid={
                          element.bild.childImageSharp
                            .fluid
                        }
                        imgStyle={{
                          objectFit: "cover",
                          objectPosition: "50% 50%",
                        }}
                      />
                  </div>
                })
              }
            </SlickSlider>
            <p>{ el.beschreibung }</p>
          </Accordion>
        ))}
      </Credentials> */}
      <Jobs></Jobs>
      <Footer></Footer>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    ueber: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/ueber/"}}) {
      edges {
        node {
          frontmatter {
            marker
            einstieg
            beschreibung
            bild {
              childImageSharp {
                fluid(maxWidth: 625) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            s_bild {
              childImageSharp {
                fluid(maxWidth: 1250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    leistungen: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/leistungen/"}}) {
      edges {
        node {
          frontmatter {
            marker
            einstieg
            leistung {
              titel
              beschreibung
            }
          }
        }
      }
    }
  }
`