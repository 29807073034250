import React from 'react'

import ContentWrapper from '../styles/ContentWrapper'
import styled from 'styled-components'

import es_img from "../../content/images/elektro-schiefermayr-bild-logo.png"

const StyledContentWrapper = styled(ContentWrapper)`
    height: 700px;
    padding: 0rem ${props => props.theme.spacingXBase};

    @media (max-width: ${props => props.theme.pageWidth}) {
        height: 55vw;
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
        padding: 0rem ${props => props.theme.spacingXsm};
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
        padding: 0rem ${props => props.theme.spacingXxs};
        height: 55vw;
    }
`

const StyledImageDiv = styled.div`
    width: 100%; 
    height: 100%; 
    position: relative;
`

export default function Hero({children}) {
    return (
        <>
        <StyledContentWrapper>
            <StyledImageDiv>
                <img style={{width: '100%', height: 'auto', display: 'block'}} src={es_img} alt="Logo" />
            </StyledImageDiv>
        </StyledContentWrapper>
        </>
    )
}
